
import { defineComponent } from "vue";

export default defineComponent({
  name: "GeoLocation",
  data() {
    return {};
  },
  emits: ["addressFound"],
  methods: {
    async getGeoLocation() {
      try {
        var options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };

        if (navigator.geolocation) {
          await navigator.geolocation.getCurrentPosition(
            (position) => {
              this.$emit("addressFound", position.coords.latitude, position.coords.longitude);
            },
            (error) => {
              this.$notify({
                type: "error",
                title: "Erro ao obter localização",
                text: error.message,
                ignoreDuplicates: true,
              });
            },
            options
          );
        } else {
          this.$notify({
            type: "error",
            title: "Navegador não suportado",
            text: "O seu navegador não tem suporte para obtenção de localização.",
            ignoreDuplicates: true,
          });
        }
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Erro ao obter localização",
          text: error.message,
          ignoreDuplicates: true,
        });
      }
    },
  },
  mounted: function () {
    this.getGeoLocation();
  },
});
