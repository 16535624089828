import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import BoxUsageDetails from "@/views/BoxUsageDetails.vue";
import BoxUsageSaved from "@/views/BoxUsageSaved.vue";
import BarCodeScanning from "@/views/BarCodeScanning.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "BarCodeScanning",
    component: BarCodeScanning,
  },
  {
    path: "/boxUsageDetails",
    component: BoxUsageDetails,
    props: true,
    name: "boxUsageDetails",
  },
  {
    path: "/boxUsageSaved",
    component: BoxUsageSaved,
    name: "boxUsageSaved",
    props: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});

export default router;
