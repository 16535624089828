
import { defineComponent } from "vue";
export default defineComponent({
  name: "BoxUsageSaved",
  mounted() {
    setTimeout(() => {
      this.$router.push({ name: "BarCodeScanning" });
    }, 2000);
  },
});
