
import { defineComponent } from "vue";
import _ from 'lodash';

export default defineComponent({
  name: "BoxUsageComponent",
  props: {
    boxId: {
      type: Number,
      default: 0,
    },
    latitude: {
      type: Number,
      default: 0,
    },
    longitude: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    async createNewBoxUsageEntry() {
      if (this.boxId && this.latitude && this.longitude) {
        try {
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: await this.prepareNewUsagePayLoad(),
          };
          fetch(
            process.env.VUE_APP_API_SERVER + "/boxesUsages/",
            requestOptions
          )
            .then(async (response) => {
              const data = await response.json();
              if (!response.ok) {
                this.$notify({
                  type: "error",
                  title: "Erro ao criar novo uso da caixa",
                  text: data.message,
                  ignoreDuplicates: true,
                });
              } else {
                this.$notify({
                  type: "success",
                  title: "Registro de uso criado com sucesso",
                  ignoreDuplicates: true,
                });
              }
              return;
            })
            .catch((error) => {
              this.$notify({
                type: "error",
                title: "Erro ao criar novo uso",
                text: error.message,
                ignoreDuplicates: true,
              });
            })
            .finally(() => {
              this.$router.push({
                name: "boxUsageSaved",
              });
            });
        } catch (error) {
          this.$notify({
            type: "error",
            title: "Erro ao criar novo uso",
            text: error.message,
            ignoreDuplicates: true,
          });
        }
      } else {
        this.$notify({
          type: "error",
          title: "Dados incompletos",
          text: "Não foi possível obter todos os dados para o processo.",
          ignoreDuplicates: true,
        });
        console.log(this.boxId +', ' + this.latitude + ', '+ this.longitude)
      }
    },
    async saveBoxUsage() {
      await this.createNewBoxUsageEntry();
    },
    findLongNameOfComponents(clientAddresses, key) {
      const result = _.find(clientAddresses, function(a) {
        const item = _.find(a.address_components, function(c) {
          return c.types.includes(key);
        });
        return item;
      });
      if (!result) return '';
      
      const item = _.find(result.address_components, function(c) {
        return c.types.includes(key);
      });
      return item.long_name || '';
    },
    async prepareNewUsagePayLoad() {
      const clientAddresses = await this.executeGoogleMapsReverseGeoLocation();
      return JSON.stringify({
        box_id: this.boxId,
        usage_formatted_addres: clientAddresses[0].formatted_address
          ? clientAddresses[0].formatted_address
          : "",
        usage_street_number: this.findLongNameOfComponents(clientAddresses, 'street_number'),
        usage_route: this.findLongNameOfComponents(clientAddresses, 'route'),
        usage_sublocality: this.findLongNameOfComponents(clientAddresses, 'sublocality_level_1'),
        usage_city: this.findLongNameOfComponents(clientAddresses, 'administrative_area_level_2'),
        usage_state: this.findLongNameOfComponents(clientAddresses, 'administrative_area_level_1'),
        usage_country: this.findLongNameOfComponents(clientAddresses, 'country'),
        usage_zip_code: this.findLongNameOfComponents(clientAddresses, 'postal_code'),
        usage_latitute: this.latitude,
        usage_longitude: this.longitude,
      });
    },
      async executeGoogleMapsReverseGeoLocation() {
      try {
        const response = await fetch(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            this.latitude +
            "," +
            this.longitude +
            "&key=AIzaSyDMOYCKCxpQIJ8SoHZCPY3TP4kgBq17ZOs"
        );
        const data = await response.json();

        if (data.error_message) {
          this.$notify({
            type: "error",
            title: "Erro ao buscar endereço GoogleMaps",
            text: data.error_message,
            ignoreDuplicates: true,
          });
        } else {
          if (data.results) {
            return data.results;
          } else {
            this.$notify({
              type: "error",
              title: "Endereço não encontrado",
              text: "Desculpe, houve um problema ao obter sua localização. Por favor, recarregue a página e tente novamente.",
              ignoreDuplicates: true,
            });
          }
        }
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Erro ao buscar endereço",
          text: "Desculpe, houve um problema ao obter seu endereço. Por favor, recarregue a página e tente novamente.",
          ignoreDuplicates: true,
        });
      }
    },
  },
  watch: {
    boxId: async function () {
      await this.saveBoxUsage();
    },
  },
});
