
import { defineComponent } from "vue";
import GeoLocation from "./components/GeoLocation.vue";
import BoxComponent from "./components/BoxComponent.vue";
import BoxUsageComponent from "./components/BoxUsageComponent.vue";

export default defineComponent({
  name: "App",
  data() {
    return {
      decodedCode: "",
      deleteBoxes: false,
      boxId: 0,
      longitude: 0,
      latitude: 0,
    };
  },
  components: { GeoLocation, BoxComponent, BoxUsageComponent },
  methods: {
    codeDecoded({ codeDecoded, deleteBoxes }) {
      this.decodedCode = codeDecoded;
      this.deleteBoxes = deleteBoxes;
    },
    addressFound(lat, long) {
      this.latitude = lat;
      this.longitude = long;
    },
    boxReturned(boxId) {
      this.boxId = boxId;
    },
    boxDeleted() {
      this.$notify({
        type: "success",
        title: "Caixa Inativada com sucesso",
        ignoreDuplicates: true,
      });
    },
  },
});
