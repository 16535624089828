
import { defineComponent } from "vue";

export default defineComponent({
  name: "BoxComponent",
  props: {
    decodedCode: {
      type: String,
      default: "",
    },
    deleteBoxes: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      boxId: 0,
    };
  },
  methods: {
    async getBoxData() {
      if (this.decodedCode) {
        try {
          const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            process.env.VUE_APP_API_SERVER +
              "/boxes/barcode/with-usage-check/" +
              this.decodedCode,
            requestOptions
          )
            .then(async (response) => {
              const data = await response.json();
              if (!response.ok) {
                this.$notify({
                  type: "error",
                  title: "Erro ao obter dados da caixa",
                  text: data.message,
                  ignoreDuplicates: true,
                });
                return;
              }
              if (data.withIn12Hours) {
                this.$notify({
                  type: "error",
                  title: "Erro ao obter dados da caixa",
                  text: "Produto foi cadastrado recentemente",
                  ignoreDuplicates: true,
                });
              } else if (data.id) {
                this.boxId = data.id;
                this.$emit("boxReturned", this.boxId);
              } else {
                await this.createNewBox();
              }
            })
            .catch((error) => {
              this.$notify({
                type: "error",
                title: "Erro ao obter dados da caixa",
                text: error.message,
                ignoreDuplicates: true,
              });
            });
        } catch (error) {
          this.$notify({
            type: "error",
            title: "Erro ao obter dados da caixa",
            text: error.message,
            ignoreDuplicates: true,
          });
        }
      } else {
        this.$notify({
          type: "error",
          title: "Erro ao obter dados da caixa",
          text: "String decodificada vazia",
          ignoreDuplicates: true,
        });
      }
    },

    async createNewBox() {
      try {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            box_bar_code: this.decodedCode,
          }),
        };
        fetch(process.env.VUE_APP_API_SERVER + "/boxes/", requestOptions)
          .then(async (response) => {
            const data = await response.json();
            if (!response.ok) {
              this.$notify({
                type: "error",
                title: "Erro ao criar nova caixa",
                text: data.message,
                ignoreDuplicates: true,
              });
              return;
            } else {
              this.boxId = data.id;
              this.$emit("boxReturned", this.boxId);
            }
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              title: "Erro ao criar nova caixa",
              text: error.message,
              ignoreDuplicates: true,
            });
          });
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Erro ao criar nova caixa",
          text: error.message,
          ignoreDuplicates: true,
        });
      }
    },

    async deleteBox() {
      try {
        const requestOptions = {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        };
        fetch(
          process.env.VUE_APP_API_SERVER + "/boxes/barcode/" + this.decodedCode,
          requestOptions
        )
          .then(async (response) => {
            const data = await response.json();
            if (!response.ok) {
              this.$notify({
                type: "error",
                title: "Erro ao inativar caixa",
                text: data.message,
                ignoreDuplicates: true,
              });
              return;
            } else {
              this.$emit("boxDeleted");
            }
          })
          .catch((error) => {
            this.$notify({
              type: "error",
              title: "Erro ao inativar caixa",
              text: error.message,
              ignoreDuplicates: true,
            });
          });
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Erro ao inativar caixa",
          text: error.message,
          ignoreDuplicates: true,
        });
      }
    },
  },
  watch: {
    decodedCode: async function () {
      if (!this.deleteBoxes) {
        await this.getBoxData();
      } else {
        await this.deleteBox();
      }
    },
  },
  emits: ["boxReturned", "boxDeleted"],
});
