
import { defineComponent } from "vue";
import BarCodeReader from "@/components/BarCodeReader.vue";

export default defineComponent({
  name: "BarCodeScanning",
  components: {
    BarCodeReader,
  },
  emits: ["success"],
  methods: {
    codeDecoded(decodedCode) {
      var strPosition = decodedCode.lastIndexOf("/");
      var deleteBoxes = this.$refs.chkDeleteBoxes.checked;

      if (strPosition > 0) {
        decodedCode = decodedCode.substring(
          strPosition + 1,
          decodedCode.length
        );
      }
      //this.$emit("codeDecoded", decodedCode);
      this.$emit("success", {
        codeDecoded: decodedCode,
        deleteBoxes: deleteBoxes,
      });
    },
    manualCodeEntered(e) {
      var decodedCode;
      var deleteBoxes = this.$refs.chkDeleteBoxes.checked;
      if (e.target.value) {
        decodedCode = e.target.value;
        var strPosition = decodedCode.lastIndexOf(";");

        if (strPosition > 0) {
          decodedCode = decodedCode.substring(
            strPosition + 1,
            decodedCode.length
          );
        } else {
          decodedCode = e.target.value;
        }
        //this.$emit("codeDecoded", decodedCode);
        this.$emit("success", {
          codeDecoded: decodedCode,
          deleteBoxes: deleteBoxes,
        });
      }
    },
    deleteBoxesCheckBoxesClicked(e) {
      if (e.target.checked) {
        if (
          !confirm(
            "Deseja iniciar a inativação de caixas?\nCaixas desativadas NÂO podem ser ativadas novamente."
          )
        ) {
          e.target.checked = false;
        }
      }
    },
  },
  mounted() {
    this.$refs.txtManualCodeRef.focus();
  },
});
