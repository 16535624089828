
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  components: {
    StreamBarcodeReader,
  },
  emits: ["readerCodeDecoded"],
  methods: {
    async onInit(promise) {
      try {
        const { capabilities } = await promise;
      } catch (error) {
        this.$notify({
          type: "error",
          title: "O aplicativo precisa de permissão de acesso a sua câmera.",
          text: error.message,
          ignoreDuplicates: true,
        });
      }
    },
    readerCodeDecoded(decodedString: string) {
      if (decodedString) {
        this.$emit("readerCodeDecoded", decodedString);
      }
      else{
        this.$notify({
          type: "error",
          title: "Erro ao recodificar o código",
          text: "Se esse erro persistir, contacte o suporte.",
          ignoreDuplicates: true,
        });
      }
    },
  },
};
