
import { defineComponent } from "vue";
import VueQRCodeComponent from "vue-qrcode-component";
import GeoLocation from "@/components/GeoLocation.vue";
import BoxComponent from "@/components/BoxComponent.vue";

export default defineComponent({
  name: "BoxUsageDetails",
  components: {
    GeoLocation,
    BoxComponent,
    VueQRCodeComponent,
  },
  emits: ["qrCodeDecoded"],
  props: {
    decodedQrCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      clientAddress: "",
      boxId: "",
      decodedBoxQrCode: "",
    };
  },
  methods: {
    addressFound(clientAddress) {
      this.clientAddress = clientAddress;
    },
    boxFound(boxId) {
      this.boxId = boxId;
    },
    reset() {
      this.$router.push({ name: "QrCodeScanning" });
    },
    saveBoxUsage() {
      this.createNewBoxUsageEntry();
      this.$router.push({ name: "boxUsageSaved" });
    },
    async createNewBoxUsageEntry() {
      if (this.boxId) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            box_id: this.boxId,
            usage_observation: "Box Usage Observation for box id " + this.boxId,
            user_id: 1,
            box_usage_city: this.clientAddress,
          }),
        };
        fetch(process.env.VUE_APP_API_SERVER + "/boxesUsages/", requestOptions)
          .then(async (response) => {
            const data = await response.json();
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
          })
          .catch((error) => {
            this.errorMessage = error;
            console.error("There was an error on createNewBoxUsage", error);
          });
      } else {
        console.log("box id not found");
      }
    },
  },
  mounted() {
    this.decodedBoxQrCode = this.decodedQrCode;
  },
});
